import React, {createElement} from 'react';

import Typography from '@mui/material/Typography';

import {graphql} from 'gatsby';
import rehypeReact from 'rehype-react';

import Seo from 'components/common/Seo';
import Layout from 'components/layout/Layout';

const renderAst = new rehypeReact({
  createElement,
}).Compiler;

export default function BlogPost({ data }) {
  return (
    <Layout inverseBackground={false}>
      <Seo title={data.page.frontmatter.title}/>
      <div>
        <Typography component="span">{renderAst(data.page.htmlAst)}</Typography>
      </div>
    </Layout>
  );
}


export const query = graphql`
  query ($slug: String!, $language: String!) {
    page: markdownRemark(
      fields: { slug: { regex: $slug } }
      frontmatter: { language: { eq: $language } }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

